<style scoped>
div {
  user-select: none;
  overflow: hidden;
}
</style>

<template>
  <div :style="style" @click="onClick">
    {{ control.config.text || "" }}
  </div>
</template>

<script>
import proto from "../utils/proto";
import client from "../utils/client";
import RES from "../utils/res";

export default {
  props: { control: Object, isView: Boolean },
  data() {
    return {
      key: "",
      url: "",
    };
  },
  computed: {
    style() {
      let result = JSON.parse(JSON.stringify(this.control.style));
      if (result["background-image"]) {
        let imgValue = result["background-image"];
        if (imgValue.indexOf("url") != 0 && imgValue.length == 36) {
          if (this.key != imgValue || this.url == "") {
            this.getResource(imgValue);
          }
          result["background-image"] = `url('${this.url}')`;
        }
      }
      if (this.isView && this.control.config.actions.length) {
        result["cursor"] = "pointer";
      }
      if (this.control.config.lines == "multi") {
        result["word-break"] = "break-all";
      } else {
        result["text-overflow"] = "ellipsis";
        result["white-space"] = "nowrap";
      }
      return result;
    },
  },
  methods: {
    async getResource(id) {
      this.key = id;
      this.url = RES.loading;
      await proto.sleep(10);
      this.url = await RES.getResource(id);
    },
    onClick() {
      if (this.isView) {
        for (let i in this.control.config.actions) {
          let action = this.control.config.actions[i];
          if (action.action.type) {
            client.$emit(action.action.type, action.action);
          }
        }
      }
    },
  },
};
</script>
